html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, main, input, textarea {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

/* HTML5 display-role reset for shitty browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, main {
  display: block;
}

ol, ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  overflow-y: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

body * {
  outline: none;
  box-sizing: border-box;
}

/* 2dp elevation modified*/
.z-depth-1, .btn, .btn-large {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}

.z-depth-1-half, .btn:hover, .btn-large:hover {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.14), 0 1px 7px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -1px rgba(0, 0, 0, 0.2);
}

/* Checkboxes
   ========================================================================== */
/* Remove default checkbox */
[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}

[type="checkbox"] {
  /* checkbox aspect */
}
[type="checkbox"] + span:not(.lever) {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  display: inline-block;
  height: 25px;
  line-height: 25px;
  font-size: 1rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
[type="checkbox"] + span:not(.lever):before, [type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
  z-index: 0;
  border: 2px solid #fff;
  border-radius: 1px;
  margin-top: 3px;
  transition: .2s;
}
[type="checkbox"]:not(.filled-in) + span:not(.lever):after {
  border: 0;
  transform: scale(0);
}
[type="checkbox"]:not(:checked):disabled + span:not(.lever):before {
  border: none;
  background-color: red;
}
[type="checkbox"].tabbed:focus + span:not(.lever):after {
  transform: scale(1);
  border: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.1);
}

[type="checkbox"]:checked + span:not(.lever):before {
  top: -4px;
  left: -5px;
  width: 12px;
  height: 22px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(40deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%;
}
[type="checkbox"]:checked:disabled + span:before {
  border-right: 2px solid red;
  border-bottom: 2px solid red;
}

/* Indeterminate checkbox */
[type="checkbox"]:indeterminate + span:not(.lever):before {
  top: -11px;
  left: -12px;
  width: 10px;
  height: 22px;
  border-top: none;
  border-left: none;
  border-right: #fff;
  border-bottom: none;
  transform: rotate(90deg);
  backface-visibility: hidden;
  transform-origin: 100% 100%;
}
[type="checkbox"]:indeterminate:disabled + span:not(.lever):before {
  border-right: 2px solid red;
  background-color: transparent;
}

[type="checkbox"].filled-in + span:not(.lever):after {
  border-radius: 2px;
}
[type="checkbox"].filled-in + span:not(.lever):before,
[type="checkbox"].filled-in + span:not(.lever):after {
  content: '';
  left: 0;
  position: absolute;
  /* .1s delay is for check animation */
  transition: border .25s, background-color .25s, width .20s .1s, height .20s .1s, top .20s .1s, left .20s .1s;
  z-index: 1;
}
[type="checkbox"].filled-in:not(:checked) + span:not(.lever):before {
  width: 0;
  height: 0;
  border: 3px solid transparent;
  left: 6px;
  top: 10px;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}
[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border: 2px solid #fff;
  top: 0px;
  z-index: 0;
}
[type="checkbox"].filled-in:checked + span:not(.lever):before {
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-left: 2px solid transparent;
  border-right: 2px solid red;
  border-bottom: 2px solid red;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
}
[type="checkbox"].filled-in:checked + span:not(.lever):after {
  top: 0;
  width: 20px;
  height: 20px;
  border: 2px solid red;
  background-color: red;
  z-index: 0;
}
[type="checkbox"].filled-in.tabbed:focus + span:not(.lever):after {
  border-radius: 2px;
  border-color: #fff;
  background-color: rgba(0, 0, 0, 0.1);
}
[type="checkbox"].filled-in.tabbed:checked:focus + span:not(.lever):after {
  border-radius: 2px;
  background-color: red;
  border-color: red;
}
[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):before {
  background-color: transparent;
  border: 2px solid transparent;
}
[type="checkbox"].filled-in:disabled:not(:checked) + span:not(.lever):after {
  border-color: transparent;
  background-color: red;
}
[type="checkbox"].filled-in:disabled:checked + span:not(.lever):before {
  background-color: transparent;
}
[type="checkbox"].filled-in:disabled:checked + span:not(.lever):after {
  background-color: red;
  border-color: red;
}

.btn, .btn-large,
.btn-flat {
  border: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 2em;
  line-height: 2em;
  padding: 0 0.5em;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-tap-highlight-color: transparent;
}

.btn.disabled, .disabled.btn-large,
.btn-floating.disabled,
.btn-large.disabled,
.btn-small.disabled,
.btn-flat.disabled,
.btn:disabled,
.btn-large:disabled,
.btn-floating:disabled,
.btn-large:disabled,
.btn-small:disabled,
.btn-flat:disabled,
.btn[disabled],
[disabled].btn-large,
.btn-floating[disabled],
.btn-large[disabled],
.btn-small[disabled],
.btn-flat[disabled] {
  pointer-events: none;
  background-color: grey !important;
  box-shadow: none;
  color: #fff !important;
  cursor: default;
}
.btn.disabled:hover, .disabled.btn-large:hover,
.btn-floating.disabled:hover,
.btn-large.disabled:hover,
.btn-small.disabled:hover,
.btn-flat.disabled:hover,
.btn:disabled:hover,
.btn-large:disabled:hover,
.btn-floating:disabled:hover,
.btn-large:disabled:hover,
.btn-small:disabled:hover,
.btn-flat:disabled:hover,
.btn[disabled]:hover,
[disabled].btn-large:hover,
.btn-floating[disabled]:hover,
.btn-large[disabled]:hover,
.btn-small[disabled]:hover,
.btn-flat[disabled]:hover {
  background-color: grey !important;
  color: #fff !important;
}

.btn, .btn-large,
.btn-floating,
.btn-large,
.btn-small,
.btn-flat {
  font-size: 1em;
  outline: 0;
}
.btn i, .btn-large i,
.btn-floating i,
.btn-large i,
.btn-small i,
.btn-flat i {
  font-size: 1em;
  line-height: inherit;
}

.btn:focus, .btn-large:focus,
.btn-floating:focus {
  background-color: #e6e6e6;
}

.btn, .btn-large {
  text-decoration: none;
  color: #033780;
  background-color: #fff;
  text-align: center;
  letter-spacing: .5px;
  transition: background-color .2s ease-out;
  cursor: pointer;
}
.btn:hover, .btn-large:hover {
  background-color: #044cb2;
  color: #fff;
}

.btn-large {
  height: 4em;
  line-height: 4em;
  font-size: 2em;
  padding: 0 28px;
}

body * {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
}

body {
  background-color: #033780;
  color: #fff;
}

p {
  margin: 0 0 2em 0;
}

.main {
  width: 100vw;
  height: 100vh;
}
.main .time {
  font-size: 50vw;
  text-align: center;
}
.main button {
  margin: 0 0 0 2em;
}

.settings-form {
  width: 50vw;
  margin: 10em auto 0;
}
.settings-form .input-field {
  margin: 0 0 3em 0;
}
.settings-form .input-field label {
  margin: 0 2em 0 0;
}
.settings-form .input-field input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #9e9e9e;
  border-radius: 0;
  color: #fff;
  outline: none;
  height: 3rem;
  width: 100%;
  font-size: 16px;
  margin: 0 0 8px 0;
  padding: 0;
  box-shadow: none;
  box-sizing: content-box;
  transition: box-shadow .3s, border .3s;
  transition: box-shadow .3s, border .3s, -webkit-box-shadow .3s;
}

.timer-buttons {
  font-size: 1.5em;
}
@media only screen and (min-width: 1025px) {
  .timer-buttons {
    display: none;
  }
}
